<template>
  <div class="wrap">
    <div class="header">
      <div class="avatar">
        <img alt :src="userInfo.avatar" />
      </div>
      <div class="text">
        <div class="line">Hello，欢迎使用</div>
        <div class="line">MinoTrend即时消息服务</div>
      </div>
    </div>
    <div class="tips" v-show="!userInfo.subscribe">
      <div class="text">
        请关注公众号，否则不能接收通知
      </div>
      <van-icon size=".8rem" :name="require('@/assets/images/refresh.svg')" @click="refreshUserInfo" />
    </div>
    <div class="tips" v-show="bindAccounts.length && hasExpire">
      <div class="text">
        您的VIP特权已过期，请前往后台续费
      </div>
      <van-icon size=".8rem" :name="require('@/assets/images/refresh.svg')" @click="getBindAccountData" />
    </div>
    <div class="bind-status">
      <div class="bind-accounts" v-show="bindAccounts.length">
        <div class="accounts-box">
          <div class="text">已绑定店铺：</div>
          <div class="accounts">
            {{ bindAccountsNameText }}
          </div>
        </div>
        <div class="right" @click="toBindPage" >
          <img :src="require('@/assets/images/add.png')" alt="">
        </div>
      </div>
      <div class="bind-nothing" v-show="!bindAccounts.length">
        <div class="text">
          点击此处绑定店铺
        </div>
        <van-icon size="1.6rem" :name="require('@/assets/images/right.svg')" @click="toBindPage" />
      </div>
    </div>

    <div class="message-num-wrap">
      <div class="title tt-wrap">
        <div class="tl">未处理消息</div>
<!--        <div class="rl" @click="updateAllTipsShow = true">消息状态同步</div>-->
      </div>
      <div class="message-num">
        <div class="message" @click="toQuestionPage(-1, unHandleQuestionsNum)">
          <div class="text">售前咨询</div>
          <div class="num">({{ unHandleQuestionsNum }})</div>
        </div>
        <div class="message" @click="toMessagePage(-1, unHandleMessagesNum)">
          <div class="text">售后消息</div>
          <div class="num">({{ unHandleMessagesNum }})</div>
        </div>
<!--        <div class="message plan">
          <img alt="" class="transparent" :src="require('@/assets/images/message-plan.png')" />
          <div class="text">售后消息 （规划中）</div>
        </div>-->
      </div>
    </div>

    <div class="message-num-wrap">
      <div class="title">已处理消息</div>
      <div class="message-num handled">
        <div class="message" @click="toQuestionPage(1, handledQuestionsNum)">
          <div class="text">售前咨询</div>
          <div class="num">({{ handledQuestionsNum }})</div>
        </div>
        <div class="message" @click="toMessagePage(1, handledMessagesNum)">
          <div class="text">售后消息</div>
          <div class="num">({{ handledMessagesNum }})</div>
        </div>
<!--        <div class="message plan">
          <img alt="" :src="require('@/assets/images/message-plan.png')" />
        </div>-->
      </div>
    </div>

    <van-dialog
        v-model="updateAllTipsShow"
        title="同步所有「未处理」的消息状态？"
        show-cancel-button
        @confirm="updateUnAnswerQuestion"
    >
      <div class="all" style="font-size: .8rem;text-align: left;padding: .3rem 1rem;">
        <div class="mm" style="padding: .1rem 0;">*在不同设备/平台回复的内容将得到更新同步</div>
        <div class="dd" style="padding: .1rem 0;font-weight: bold; color: red;">*美客多后台中将不再显示当前未回复的Questions数量</div>
      </div>
    </van-dialog>

    <chat></chat>

  </div>
</template>

<script>

import {getHandleMessageStatus} from "@/api/message";
import {getBindAccounts} from "@/api/bindData";
import {refreshUserInfo} from "@/api/userInfo";
import {SET_USER_INFO} from "@/store/mutation-types";
import request from "@/utils/request";
import {showChatSystem, validUserFilter} from "@/utils/chat";
import chat from '@/components/chat'

const refreshUserInfoStorageKey = 'refreshUserInfoStorageKey2';
export default {
  name: 'index',
  components: {
    chat
  },
  data() {
    return {
      hasExpire: false,
      bindAccounts: [],
      unHandleQuestionsNum: 0,
      unHandleMessagesNum: 0,
      handledQuestionsNum: 0,
      handledMessagesNum: 0,
      updateAllTipsShow: false,
    }
  },
  computed: {
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    bindAccountsNameText: function() {
      const accountNames = [];
      this.bindAccounts.forEach(account => {
        accountNames.push(account.name);
      })
      return accountNames.join('、');
    },
  },
  created() {
    this.getHandleMessageStatus();
    this.getBindAccountData();

    // 第一次进入刷新用户信息，每12小时刷新一次
    const nowTime = (new Date()).getTime();
    const lastTime = localStorage.getItem(refreshUserInfoStorageKey);
    if (!lastTime || nowTime - lastTime >= 12 * 3600 * 1000) {
      localStorage.setItem(refreshUserInfoStorageKey, nowTime);
      this.refreshUserInfo();
    }

  },
  mounted() {
    showChatSystem();
  },
  methods: {
    // 获取未处理的信息数量
    getHandleMessageStatus() {
      getHandleMessageStatus()
        .then(res => {
          if (res.error === 0) {
            this.unHandleQuestionsNum = res.data.unHandleQuestionsNum;
            this.unHandleMessagesNum = res.data.unHandleMessagesNum;
            this.handledQuestionsNum = res.data.handledQuestionsNum;
            this.handledMessagesNum = res.data.handledMessagesNum;
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          console.log('请求处理信息数量错误', error);
        })
    },

    // 获取绑定的账号
    getBindAccountData() {
      getBindAccounts()
        .then(res => {
          if (res.error === 0) {
            let hasExpire = false;
            res.data.forEach(account => {
              if (account.isExpire) {
                hasExpire = true;
              }
            })
            this.hasExpire = hasExpire
            this.bindAccounts = res.data;
          } else {
            this.$errorMessage(res.message)
          }
        })
        .catch(error => {
          console.log('请求绑定信息失败', error)
        })
    },

    // 刷新用户信息
    refreshUserInfo() {
      refreshUserInfo()
        .then(res => {
          if (res.error === 0) {
            this.$store.commit(SET_USER_INFO, res.data);
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          console.log('刷新用户信息失败', error);
        })
    },

    toBindPage() {
      this.$router.push({
        name: 'bind',
      })
    },

    toQuestionPage(handle, num) {
      if (!num || num < 0) {
        return false;
      }

      this.$router.push({
        name: 'question',
        query: {
          handle,
        }
      });
    },

    toMessagePage(handle, num) {
      if (!num || num < 0) {
        return false;
      }

      this.$router.push({
        name: 'message',
        query: {
          handle,
        }
      })
    },

    // 更新全部未读信息
    updateUnAnswerQuestion() {
      this.updateAllTipsShow = false;
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '同步中...',
      });

      request({
        url: 'question/un-answer/update',
        method: 'post',
        timeout: 90000,
      })
        .then(res => {
          toast.close();
          if (res.error === 0) {
            this.$successMessage('同步完成！');
            this.getHandleMessageStatus();
          } else {
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          toast.close();
          console.log('更新全部问题错误');
          this.$errorMessage('更新失败，请稍后再试');
        })
    }

  }
}
</script>

<style lang="less" scoped>
  .wrap{
    padding: .8rem 1.5rem;
    color: #333333;
  }
  .header{
    display: flex;
    padding: 0 0 1rem 0rem;
    .avatar{
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1.7rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .text{
      text-align: left;
      padding: .4rem 0 0;
      font-size: 1.2rem;
      color: #000000;
      font-weight: bold;
    }
  }
  .tips{
    background-color: #fdefee;
    border-radius: .3rem;
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;
    padding: .5rem .8rem;
    align-items: center;
    .text{
      font-size: .9rem;
      color: #666666;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .bind-status{
    background-color: #f6f6f6;
    border-radius: .3rem;
    text-align: left;
    padding: .5rem .6rem;
    margin: .3rem 0 0;
    .bind-accounts{
      display: flex;
      justify-content: space-between;
      .accounts-box{
        flex: 1 1 auto;
        max-width: 80%;
        .text{
          font-size: .8rem;
        }
        .accounts{
          font-size: .9rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: .2rem 0;
        }
      }
      .right{
        display: flex;
        align-items: center;
        padding: 0 0 0 .5rem;
        color: #aaaaaa;
        .arrow{

        }
        img{
          width: 38px;
          height: 38px;
        }
      }
    }
    .bind-nothing{
      display: flex;
      justify-content: space-between;
      color: #666666;
      padding: .3rem .5rem;
    }
  }

  .message-num-wrap{
    margin: 1.6rem 0 0;
    .title{
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
    }
    .tt-wrap{
      display: flex;
      justify-content: space-between;
      .rl{
        font-size: .9rem;
        padding: .12rem .22rem;
        background-color: #4091f7;
        color: #ffffff;
        border-radius: .3rem;
      }
    }
    .message-num{
      background-color: #fdf4e9;
      border-radius: .3rem;
      display: flex;
      padding: 1rem 3rem;
      justify-content: space-between;
      margin-top: .1rem;
      &.handled{
        background-color: #e5f0f0;
      }
      .message{
        background-color: #ffffff;
        padding: 1.2rem .6rem;
        border-radius: .2rem;
        width: 5.6rem;
        height: 5.6rem;
        box-sizing: border-box;
        &.plan{
          padding: .8rem .6rem;
          position: relative;
          img{
            position: absolute;
            top: .6rem;
            left: .45rem;
            z-index: 99;
            display: flex;
            &.transparent{
              opacity: .3;
            }
          }
          .text{
            position: absolute;
            z-index: 999;
            top: 1.2rem;
            left: .1rem;
          }
        }
        .text{
          padding: 0 0 .1rem;
        }
        img{
          width: 4.5rem;
          height: 4.5rem;
        }
      }
    }
  }
</style>
