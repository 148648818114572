import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: 'MinoTrend for Mercadolibre',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login'),
    meta: {
      title: 'MinoTrend for Mercadolibre',
    }
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import('../views/bind'),
    meta: {
      title: 'MinoTrend for Mercadolibre',
    }
  },

  {
    path: '/question/:questionId/history',
    name: 'questionHistory',
    component: () => import('../views/questionHistory'),
    meta: {
      title: '历史咨询',
    }
  },
  {
    path: '/question/:questionId',
    name: 'questionDetail',
    component: () => import('../views/questionDetail'),
    meta: {
      title: '售前咨询',
    }
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/question'),
    meta: {
      title: '售前咨询',
    }
  },
  {
    path: '/message/:orderId/:oUserId',
    name: 'messageDetail',
    component: () => import('../views/messageDetail'),
    meta: {
      title: '售后信息',
    }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message'),
    meta: {
      title: '售后信息',
      autoTitle: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
