import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {getToken, removeToken} from '@/utils/auth' // get token from cookie
import router from "@/router";
import store from "@/store"
import {GET_USER_INFO} from "@/store/mutation-types";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // 修改标题
  const defaultTitle = 'MinoTrend for Mercadolibre';
  if (!to.meta || !to.meta.autoTitle) {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title;
    } else {
      document.title = defaultTitle;
    }
  }

  // start progress bar
  NProgress.start();

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (store.state.userInfo) {
      next();
      NProgress.done();
    } else {
      store.dispatch(GET_USER_INFO)
        .then(() => {
          next();
          NProgress.done();
        })
        .catch(error => {
          removeToken();
          next({
            path: `login?redirect=${to.fullPath}`,
          })
          NProgress.done();
        });
    }
  } else {
    if (whiteList.indexOf(to.name) !== -1) {
      next()
      NProgress.done();
    } else {
      next({
        path: `login?redirect=${to.fullPath}`,
      })
      NProgress.done()
    }
  }
})
